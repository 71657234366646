function setAuth(token) {
  localStorage.setItem('token', token)
}

function unsetAuth() {
  localStorage.removeItem('token')
  localStorage.removeItem('user')
  localStorage.removeItem('isAdmin')
}

function getAuthHeader() {
  const token = localStorage.getItem('token')
  if (!token) return {}
  return {
    Authorization: 'Bearer ' + token,
  }
}

function getToken() {
  return localStorage.getItem('token')
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  setAuth,
  getAuthHeader,
  getToken,
  unsetAuth,
}
