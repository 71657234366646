import axios from 'axios'
import auth from 'src/lib/auth'
import handleResponses from 'src/lib/handleResponses'
import globals from '../config/globals'

const api = axios.create({
  baseURL: globals().publicUrl,
})

api.defaults.headers.common['Content-Type'] = 'application/json'

api.interceptors.request.use(
  (config) => {
    const { Authorization } = auth.getAuthHeader()
    if (Authorization) {
      config.headers['Authorization'] = Authorization
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)

api.interceptors.response.use(
  (response) => {
    return handleResponses.responseHandler(response)
  },
  (error) => {
    return Promise.reject(error)
  },
)

export default api
