import React from 'react'
import PropTypes from 'prop-types'
import {
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CFormLabel,
  CModalFooter,
  CButton,
} from '@coreui/react'

/**
 * @typedef {Object} ModalDeleteProps
 * @prop {boolean} visible
 * @prop {Function} onClose
 * @prop {Function} onConfirm
 * @prop {Object} messages
 * @prop {string} messages.title
 * @prop {string} messages.message
 * @prop {string} messages.footer
 */

/**
 * @param {ModalDeleteProps} props
 * @returns {React.JSX.Element}
 */

export function ModalGeneric({
  visible,
  onClose,
  onConfirm,
  messages: { title, message, footer },
  type = 'ACTION',
}) {
  return (
    <CModal alignment="center" visible={visible} onClose={onClose}>
      <CModalHeader>
        <CModalTitle>{title}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CFormLabel>{message}</CFormLabel>
      </CModalBody>
      <CModalFooter
        style={{ display: 'flex', justifyContent: type === 'INFO' ? 'end' : 'space-between' }}
      >
        {type === 'INFO' ? (
          <CButton color="primary" onClick={onClose}>
            OK
          </CButton>
        ) : (
          <>
            <CButton color="secondary" onClick={onClose}>
              Não
            </CButton>
            <CButton color="primary" onClick={onConfirm}>
              Sim
            </CButton>
          </>
        )}
      </CModalFooter>
      {footer && <p className="text-danger mx-3">{footer}</p>}
    </CModal>
  )
}

ModalGeneric.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  messages: PropTypes.shape({
    title: PropTypes.string,
    message: PropTypes.string.isRequired,
    footer: PropTypes.string,
  }),
  type: PropTypes.string,
}
