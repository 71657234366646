import api from './api'

async function executeCronJob({ crons }) {
  return api.post('/schedule/force', { crons })
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  executeCronJob,
}
