import auth from '../lib/auth'

function errorHandler(err) {
  if (err && err.response && [401, 403].includes(err.response.status)) auth.unsetAuth()
  throw err
}

function responseHandler(res) {
  return res.data
}

function handleLoginResponse(res) {
  if (res.status !== 200 && res.status !== 201) {
    return Promise.reject(new Error('Login failed'))
  }
  return res.text().then(JSON.parse)
}

function handleResponseStatus(res) {
  if (res.status !== 200 && res.status !== 201) {
    return Promise.reject(new Error('Request failed'))
  }

  return res.data
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  responseHandler,
  errorHandler,
  handleLoginResponse,
  handleResponseStatus,
}
