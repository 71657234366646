import { env } from './env'

export function createDynamicValueFromEnv(environment = 'pro', key) {
  const KEY_NAME = `REACT_APP_${environment.toUpperCase()}_${key}`

  const [value, simpleValue, emptyValue] = [env[KEY_NAME], env[key], '']

  if (value !== undefined) {
    return value
  }

  if (simpleValue !== undefined) {
    return simpleValue
  }

  return emptyValue
}
