/* eslint-disable no-loop-func */
import React, { useEffect, useState } from 'react'
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CForm,
  CRow,
  CFormCheck,
  CButton,
  CAlert,
} from '@coreui/react'
import { ModalGeneric } from 'src/views/components/validator/modalGeneric'
import { useNavigate } from 'react-router-dom'
import scheduleService from 'src/services/scheduleService'
import { useLoading } from 'src/components/Loading/LoadingContext'

const ForceCronJob = () => {
  const [showModal, setShowModal] = useState(false)
  const [showButtonExecute, setShowButtonExecute] = useState(true)
  const [message, setMessage] = useState('')
  const { setIsLoading } = useLoading()
  const navigate = useNavigate()
  const [modalConfig, setModalConfig] = useState({
    messages: {
      title: '',
      message: '',
    },
    type: 'INFO',
  })

  const openModal = (title, modalMessage, type) => {
    const formattedMessage = modalMessage.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        <span style={{ display: 'block', lineHeight: '1.2', marginBottom: '4px' }}>{line}</span>
      </React.Fragment>
    ))
    setModalConfig({ messages: { title, message: formattedMessage }, type })
    setShowModal(true)
  }

  const [cronJobs, setCronJobs] = useState([
    {
      id: 'CRON_RECURRENT_JOBS',
      label: 'Cron de recorrência',
      checked: false,
      text: `
      - Cria beneficiários\n
      - Deleta beneficiários\n
      - Atualiza pagamento de beneficiários\n
      - Recarga de beneficiários\n
      - Retentativa de criar beneficiários
      `,
      priority: null,
    },
    {
      id: 'CRON_ONCE_ON_BEGING_OF_DAY',
      label: 'Cron de início do dia',
      checked: false,
      text: `
      - Bloqueia todos os beneficiários do cliente ou grupo que tenham boleto atrasado\n
      - Remove o boleto atrasado caso tenha sido pago\n
      - Tenta gerar novamente o boleto do mês
      `,
      priority: null,
    },
    {
      id: 'CRON_RUN_CHANGE_OF_MONTH',
      label: 'Cron de virada de mês',
      checked: false,
      text: `
      - Cancela beneficiários com status agendado para cancelamento (beneficiários ativos e bloqueados)\n
      - Gera boletos para todos os grupos e clientes
      `,
      priority: null,
    },
    {
      id: 'CRON_RUN_AFTER_CHANGE_OF_MONTH',
      label: 'Cron de após a virada de mês',
      checked: false,
      text: `
      - Envia boletos bloqueados, novos e expirados\n
      - Envia o relatório de boletos
      `,
      priority: null,
    },
  ])

  const handleCheckboxChange = (checked, id) => {
    setCronJobs((prevCronJobs) => {
      const updatedCronJobs = prevCronJobs.map((job) =>
        job.id === id ? { ...job, checked: !job.checked } : job,
      )

      const checkedCronJobs = updatedCronJobs.filter((cron) => cron.checked)
      let sortedCronJobs = checkedCronJobs.sort((cronA, cronB) => cronA.priority - cronB.priority)

      if (checked) {
        return updatedCronJobs.map((job) => {
          if (job.id === id) {
            return { ...job, priority: checkedCronJobs.length }
          }
          return job
        })
      } else {
        return updatedCronJobs.map((job) => {
          const indexInSorted = sortedCronJobs.indexOf(job)
          return { ...job, priority: indexInSorted + 1 }
        })
      }
    })
  }

  const handleExecuteCronJob = async () => {
    setIsLoading(true)
    try {
      const cronJobPayload = cronJobs
        .filter((cron) => cron.checked)
        .map((cron) => ({
          name: cron.id,
          priority: cron.priority,
        }))

      scheduleService.executeCronJob({ crons: cronJobPayload })

      setMessage({
        text: 'O CronJob está sendo processado',
        type: 'info',
      })
    } catch (e) {
      setMessage({
        text: 'Erro ao executar CronJob',
        type: 'danger',
      })
    } finally {
      setIsLoading(false)
    }

    setTimeout(() => {
      setMessage(null)
      window.location.reload()
    }, 4000)
  }

  useEffect(() => {
    const checkedCronJobs = cronJobs.filter((cron) => cron.checked)

    if (!checkedCronJobs.length) {
      setShowButtonExecute(true)
      return
    }
    setShowButtonExecute(false)
  }, [cronJobs])

  return (
    <CRow>
      <div>{message && <CAlert color={message.type}>{message.text}</CAlert>}</div>
      <CCol xs={12}>
        <CCard className="mb-4">
          <CCardHeader>
            <strong>Forçar CronJobs</strong>
          </CCardHeader>

          <CCardBody>
            <CForm>
              {cronJobs.map((job) => {
                return (
                  <div key={job.id} className="form-group">
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        margin: '20px',
                      }}
                    >
                      <CFormCheck
                        id={job.id}
                        checked={job.checked}
                        onChange={(value) => handleCheckboxChange(value.target.checked, job.id)}
                        label={
                          <div
                            style={{ display: 'flex', alignItems: 'center', marginRight: '15px' }}
                          >
                            {job.checked && job.priority !== null && (
                              <span
                                style={{
                                  marginRight: '10px',
                                  fontSize: '1.2rem',
                                  fontWeight: 'bold',
                                }}
                              >
                                {job.priority} -
                              </span>
                            )}
                            <h5 style={{ margin: 0 }}>{job.label}</h5>
                          </div>
                        }
                        style={{
                          border: '2px solid #007bff',
                        }}
                      />
                      <CButton
                        className="m-1"
                        onClick={() => {
                          openModal(`${job.label}`, `${job.text}`, 'INFO')
                        }}
                      >
                        Info
                      </CButton>
                    </div>
                  </div>
                )
              })}
              <div
                className="filter-box actions"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'nowrap',
                  padding: '1.5rem 0 0 0',
                  columnGap: '1rem',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                }}
              >
                <button className="btn btn-secondary" type="button" onClick={() => navigate(-1)}>
                  Voltar
                </button>
                <button
                  onClick={() => handleExecuteCronJob()}
                  disabled={showButtonExecute}
                  className="btn btn-primary"
                  type="button"
                >
                  Executar
                </button>
              </div>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
      <ModalGeneric
        onConfirm={() => {
          setShowModal(false)
        }}
        messages={modalConfig.messages}
        type={modalConfig.type}
        onClose={() => setShowModal(false)}
        visible={showModal}
      />
    </CRow>
  )
}

export default ForceCronJob
