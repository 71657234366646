import React from 'react'
import { Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import { BrowserRouter } from 'react-router-dom'
import { PrivateRoute } from './components/PrivateRoute'
import { Provider } from 'react-redux'
import store from './store'
import { PrivateRouteAdmin } from './components/PrivateRouteAdmin'
import AppProvider from './context/AppProvider'
import { LoadingProvider } from './components/Loading/LoadingContext'
import ForceCronJob from './views/pages/form/ForceCronJob'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
const SetPassword = React.lazy(() => import('./views/pages/setPassword/SetPassword'))
const ResendToken = React.lazy(() => import('./views/pages/resendToken/ResendToken'))
const AdminList = React.lazy(() => import('./views/pages/admin/AdminList'))
const CreateAdmin = React.lazy(() => import('./views/pages/form/CreateAdmin'))
const GetAdmin = React.lazy(() => import('./views/pages/form/GetAdmin'))
const GroupList = React.lazy(() => import('./views/pages/group/GroupList'))
const ManagerList = React.lazy(() => import('./views/pages/manager/ManagerList'))
const ProgramList = React.lazy(() => import('./views/pages/program/ProgramList'))
const ProgramCreate = React.lazy(() => import('./views/pages/program/ProgramCreate'))
const GroupManagement = React.lazy(() => import('./views/pages/group/GroupCreate'))
const ManagerManagement = React.lazy(() => import('./views/pages/manager/ManageManagement'))
const SellerList = React.lazy(() => import('./views/pages/seller/list'))
const SellerCreate = React.lazy(() => import('./views/pages/seller/create'))
const BeneficiaryActivate = React.lazy(() =>
  import('./views/pages/beneficiary/activate/ActivateBeneficiary'),
)

const App = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <LoadingProvider>
          <AppProvider>
            <React.Suspense fallback={loading}>
              <Routes>
                <Route path="/" element={<Login />} />
                <Route exact path="/login" element={<Login />} />
                <Route exact path="/404" element={<Page404 />} />
                <Route exact path="/500" element={<Page500 />} />
                <Route exact path="/admin/new/:token" element={<SetPassword />} />
                <Route exact path="/resend-token/:token" element={<ResendToken />} />
                <Route path="/" element={<PrivateRoute />}>
                  <Route element={<DefaultLayout />}>
                    <Route path="*" name="Home" />
                    <Route path="group/list" element={<GroupList />} />
                    <Route path="group/view/:id" element={<GroupManagement />} />
                  </Route>
                </Route>
                <Route path="/" element={<PrivateRouteAdmin />}>
                  <Route element={<DefaultLayout />}>
                    <Route path="admin/list" element={<AdminList />} />
                    <Route path="/beneficiary/activate" element={<BeneficiaryActivate />} />
                    <Route path="admin/create" element={<CreateAdmin />} />
                    <Route path="admin/get" element={<GetAdmin />} />
                    <Route path="admin/force" element={<ForceCronJob />} />
                    <Route path="group/list" element={<GroupList />} />
                    <Route path="group/create" element={<GroupManagement />} />
                    <Route path="group/edit/:id" element={<GroupManagement />} />
                    <Route path="manager/create" element={<ManagerManagement />} />
                    <Route path="manager/list" element={<ManagerList />} />
                    <Route path="program/list" element={<ProgramList />} />
                    <Route path="program/create" element={<ProgramCreate />} />
                    <Route path="program/:id" element={<ProgramCreate />} />
                    <Route path="seller/list" element={<SellerList />} />
                    <Route path="seller/create" element={<SellerCreate />} />
                  </Route>
                </Route>
              </Routes>
            </React.Suspense>
          </AppProvider>
        </LoadingProvider>
      </BrowserRouter>
    </Provider>
  )
}

export default App
